<template>
  <div class="input-list">
    <select  v-model="countryList.countryActive"  @change="changeCountry($event)" name="" id="">
      <option v-for="(country,index ) in countryList.countrys" :value="country.countryId" :key="index">+{{country.countryCallingCode}} {{country.displayName}} </option>
    </select>
    <input
      @change="onInputVal"
      @blur="blur"
      v-model="mobileValue"
      class="input-d"
      
      :placeholder="$t('loginRegister.moblieInput')"
    />
    <p v-show="errShowList.errShow" class="err-text">
      <img src="../assets/images/register/dx-icon.png" alt="" />
      <span>{{errShowList.errContent}}</span>
    </p>
  </div>
</template>

<script>
import account from '@/api/account.js';
export default{
  props: {
    mobileValue: {
      type: String,
    },
    errShowList: {
      type: Object,
      default () {
        return {}
      }
    },
    countryList: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      
    }
  },
  created() {
    // console.log(value)
   
  },
  methods: {
    changeCountry(event) {
      this.countryActive = event.target.value; //获取option对应的value值
      console.log("你选中了",this.countryActive)
      this.$emit('country', this.countryActive) 
    },
    onInputVal(e) {
      this.$emit('inputVal', e.target.value) 
    },
    blur(){
      this.$emit('mobileBlur',"mobile"); 
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-list{width: 385px;height: 55px;margin-bottom: 28px;border-radius: 5px;border: 1px solid #e0e0e0;position: relative; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .icon-box{width: 58px;text-align: center;}
.input-list .flag-icon{width: 22px;height: 22px;}
.input-list .err-text{font-size: 12px;line-height: 14px; color:#fa885b;position:absolute;bottom: -22px;left: 5px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .err-text img{width: 12px;height: 12px;margin-right: 5px;}
.input-list .input-d{width: 190px;line-height: 20px;height: 20px; font-size: 14px;padding: 0 20px; color: #4c4c4c;border-left: 1px solid #e0e0e0;}
.input-list select{width: 140px;height: 55px; font-size: 16px;padding: 0 15px; color: #000;margin-right: 15px;cursor: pointer;}

@media screen and (max-width: 768px) {
.input-list{width: 100%;height: 2.5rem;margin-bottom: 1.5rem;border-radius: 5px;border: 1px solid #e0e0e0;position: relative; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .icon-box{width: 2.5rem;text-align: center;}
.input-list .flag-icon{width: 1.2rem;height: 1.2rem;}
.input-list .err-text{font-size: 0.6rem;line-height: 0.8rem; color:#fa885b;position:absolute;bottom: -1.2rem;left: 0.3rem;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .err-text img{width: 0.6rem;height: 0.6rem;margin-right: 0.3rem;}
.input-list .input-d{width: 60%;line-height: 1.2rem;height: 1.2rem; font-size: 0.7rem;padding: 0 0.8rem; color: #4c4c4c;border-left: 1px solid #e0e0e0;}
.input-list select{width: 40%;font-size: 0.7rem;padding: 0 0.5rem; color: #000;margin-right: 0.5rem;}
}
</style>
