<template>
  <div class="input-list">
    <div class="icon-box">
      <img
        class="emyx-icon"
        src="../assets/images/register/emyx-icon.png"
        alt=""
      />
    </div>
    <input
      @change="onInputVal"
      @blur="blur"
      v-model="emailValue"
      class="input-c"
      :placeholder="$t('loginRegister.emailInput')"
    />
    <p v-show="errEmailList.errShow" class="err-text">
      <img src="../assets/images/register/dx-icon.png" alt="" />
      <span>{{errEmailList.errContent}}</span>
    </p>
  </div>
</template>

<script>
export default{
  props: {
    emailValue: {
      type: String,
    },
    errEmailList: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data() {
    return {
    }
  },
  created() {
   
  },
  methods: {
    onInputVal(e) {
      this.$emit('inputVal', e.target.value) 
    },
    blur(){
      this.$emit('emailBlur',"email"); 
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-list{width: 385px;height: 55px;margin-bottom: 28px;border-radius: 5px;border: 1px solid #e0e0e0;position: relative; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .icon-box{width: 58px;text-align: center;}
.input-list .emyx-icon{width: 22px;height: 22px;}
.input-list .err-text{font-size: 12px;line-height: 14px; color:#fa885b;position:absolute;bottom: -22px;left: 5px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .err-text img{width: 12px;height: 12px;margin-right: 5px;}
.input-list .input-c{width: 286px;line-height: 20px;height: 20px; font-size: 14px;padding: 0 20px; color: #4c4c4c;border-left: 1px solid #e0e0e0;}

@media screen and (max-width: 768px) {
.input-list{width: 100%;height: 2.5rem;margin-bottom: 1.5rem;border-radius: 5px;border: 1px solid #e0e0e0;position: relative; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .icon-box{width: 2.5rem;text-align: center;}
.input-list .emyx-icon{width: 1.2rem;height: 1.2rem;}
.input-list .err-text{font-size: 0.6rem;line-height: 0.8rem; color:#fa885b;position:absolute;bottom: -1.2rem;left: 0.3rem;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .err-text img{width: 0.6rem;height: 0.6rem;margin-right: 0.3rem;}
.input-list .input-c{width: 75%;line-height: 1.2rem;height: 1.2rem; font-size: 0.7rem;padding: 0 0.8rem; color: #4c4c4c;border-left: 1px solid #e0e0e0;}
}
</style>
