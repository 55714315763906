<template>
  <div class="input-fl hide-pc">
    <h2 class="title1">{{$t('loginRegister.loginLeftTitle1')}}</h2>
    <p class="title2">{{$t('loginRegister.loginLeftTitle2')}}</p>
  </div>
</template>

<script>
export default{
  data() {
    return {
    }
  },
  created() {
   
  },
  methods: {
    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .input-fl{width: 516px;height: 100%;background: url(../assets/images/register/fl-bg1.png) no-repeat;background-size: 100% 100%;}
  .input-fl .title1{font-size: 26px;margin-top: 65px; color: #f66d1d;font-weight: bold;text-align: center;}
  .input-fl .title2{font-size: 16px;margin-top: 15px;padding: 0 20px; color: #414043;font-weight: bold; text-align: center;}
</style>
