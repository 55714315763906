<template>
  <div class="mobile-reg">
    <DowApp />
    <Header :loginState="loginState" />
    <div class="input-bg">
      <div class="input-box retrieve-password">
        <LoginLeft />
        <div class="input-fr email-fr">
          <div class="input-fr-box">
            
            <div class="form-tab">
             
              <div class="form-tab-content">
                <div v-show="cur == 0">
                  <p class="retrievePassBtn"  @click="cur = 1">{{$t('loginRegister.retrievePassTitle1')}}</p>
                  <h2>{{$t('loginRegister.retrievePassTitle2')}}</h2>
                  <div class="from-box">
                    <email-input @inputVal="inputEmailVal" @emailBlur="inputBlur" :errEmailList="errEmailList"/>
                    <code-input :verCodeData="verEmailCodeData" :errCodeList="errEmailCodeList" @codeBlur="emailCodeBlur()"  @getVerCodeEmit="getEmailCode"  @onPhoneCode="emailCodeVal"/>
                    <pass-input :errPasswordList="errEmailPasswordList" @passBlur="emailPassBlur()" @passwordVal="emailPasswordlVal"/>
                    <input @click="emailResetPassword" class="input-btn" type="button" :value="$t('loginRegister.resetPassword')" />
                  </div>
                </div>
                <div v-show="cur == 1">
                  <p class="retrievePassBtn"  @click="cur = 0">{{$t('loginRegister.retrievePassTitle2')}}</p>
                  <h2>{{$t('loginRegister.retrievePassTitle1')}}</h2>
                  <div class="from-box">
                    <mobile-input :countryList="countryList" :errShowList="errShowList" @mobileBlur="inputBlur" @country="selectCountry" @inputVal="inputPhoneVal" />
                    <code-input :verCodeData="verPhoneCodeData" :errCodeList="errPhoneCodeList" @codeBlur="phoneCodeBlur()" @getVerCodeEmit="getPhoneCode"  @onPhoneCode="phoneCodeVal"/>
                    <pass-input :errPasswordList="errPhonePasswordList" @passBlur="phonePassBlur()" @passwordVal="phonePasswordlVal"/>
                    <input @click="phoneResetPassword" class="input-btn" type="button" :value="$t('loginRegister.resetPassword')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="input-fr-bottom">
              <a href="/login">{{$t('loginRegister.returnLogin')}}</a>
            </div>
          </div>
        </div>
      </div>
      <LoginFooter />
    </div>
    <!-- 提示框组件 -->
    <Dialog ref="myConfirm"  :isShowConfirm="isShowConfirm" @userBehavior="userBehaviorFun" />
  </div>
</template>

<script>
// @ is an alias to /src
import DowApp from "@/components/DowApp.vue";
import Header from "@/components/Header.vue";
import LoginFooter from "@/components/LoginFooter.vue";
import LoginLeft from "@/components/LoginLeft.vue";
import EmailInput from '@/components/EmailInput.vue';
import PassInput from '@/components/PassInput.vue';
import CodeInput from '@/components/CodeInput.vue';
import MobileInput from '@/components/MobileInput.vue';
import Dialog from '@/components/Dialog.vue';
import account from '@/api/account.js';
import {REG_EMAIL,REG_PASSWORD} from '@/util/const.js'

export default {
  name: "RetrievePassword",
  components: {
    DowApp,
    Header,
    LoginFooter,
    LoginLeft,
    EmailInput,
    PassInput,
    CodeInput,
    MobileInput,
    Dialog
  },
  data() {  
    return {
      domainUrl:'',
      cur:0,
      loginState:1,
      isShowConfirm:false,
      phone:'',
      phoneCode:'',
      email:'',
      emailCode:'',
      phonePassword:'',
      emailPassword:'',
      verEmailCodeData: {
        isVerCode: '0',
        showTime:60,
        interval:'',
      },
      verPhoneCodeData: {
        isVerCode: '0',
        showTime:60,
        interval:'',
      },
      countryList: {
        countrys: [],
        countryActive:'',
      },
      countryCurrent:[],
      errShowList: {
        errShow:false,
        errContent:'',
      },
      errEmailList: {
        errShow:false,
        errContent:'',
      },
      errEmailCodeList: {
        errShow:false,
        errContent:'',
      },
      errPhoneCodeList: {
        errShow:false,
        errContent:'',
      },
      errEmailPasswordList: {
        errShow:false,
        errContent:'',
      },
      errPhonePasswordList: {
        errShow:false,
        errContent:'',
      } 
    }
  },
  created() {
    this.domainUrl = window.location.host;
    // 获取手机号注册的国家下拉列表数据
    account.getSupportCountry({"type":"mobile"}).then((res)=>{
     this.countryList.countrys = res.data.value
     this.countryList.countryActive = res.data.value[0].countryId //默认选中第一个国家
     let countryCurrent = this.countryList.countrys.filter(it => it.countryId == this.countryList.countryActive);//帅选选中的国家数据
     this.countryCurrent = countryCurrent;
    }).catch(err=>{
      console.log(err.data.message);
    })  
  },
  methods: {
    // 获取选中的国家及国家区码
    selectCountry(val){
      let countryCurrent = this.countryList.countrys.filter(it => it.countryId == val);//帅选选中的国家数据
      this.countryCurrent = countryCurrent;
      console.info("countryCurrent",this.countryCurrent[0].countryCallingCode);
    },

    // 获取输入的手机号
    inputPhoneVal(val){
      this.phone = val
      console.log(this.phone);
    },

    // 获取输入的手机验证码
    phoneCodeVal(val){
      this.phoneCode = val
      console.log(this.phoneCode);
    },

    // 获取输入的邮箱
    inputEmailVal(val){
      this.email = val
      console.log(this.email);
    },

    // 获取输入的邮箱验证码
    emailCodeVal(val){
      this.emailCode = val
      console.log(this.emailCode);
    },

    // 获取手机重置的输入密码
    phonePasswordlVal(val){
      this.phonePassword = val
      console.log(this.phonePassword);
    },

    // 获取邮箱重置的输入密码
    emailPasswordlVal(val){
      this.emailPassword = val
      console.log(this.emailPassword);
    },

    //获取输入的账号或邮箱
    inputAccountVal(val){
      this.accountEmail = val
      console.log(this.accountEmail);
    },
    // 获取输入的密码
    inputPasswordlVal(val){
      this.password = val
      console.log(this.password);
    },
    // 鼠标离开输入框校验不能为空
    inputBlur(val){
      if(val == "email"){
        if(!this.email){
          this.errEmailList.errShow = true;
          this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt8'); 
        }else{
          if(!REG_EMAIL.test(this.email)){
            this.errEmailList.errShow = true;
            this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt9'); 
          }else{
            this.errEmailList.errShow = false;
          }
        }      
      }
      if(val == "mobile"){
        const regPhone = eval(this.countryCurrent[0].frontendRegex);
        if(!this.phone){
          this.errShowList.errShow = true;
          this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt4'); 
        }else{
          if(!regPhone.test(this.phone)){
            this.errShowList.errShow = true;
            this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt5'); 
          }else{
            this.errShowList.errShow = false;
          }
        }        
      } 
    },
    emailCodeBlur(){
      if(!this.emailCode){
        this.errEmailCodeList.errShow = true;
        this.errEmailCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
      }else{
        this.errEmailCodeList.errShow = false;
      }
    },
    phoneCodeBlur(){
      if(!this.phoneCode){
        this.errPhoneCodeList.errShow = true;
        this.errPhoneCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
      }else{
        this.errPhoneCodeList.errShow = false;
      }
    },
    emailPassBlur(){
      if(!this.emailPassword){
        this.errEmailPasswordList.errShow = true;
        this.errEmailPasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt2'); 
      }else{
        if(!REG_PASSWORD.test(this.emailPassword)){
          this.errEmailPasswordList.errShow = true;
          this.errEmailPasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt3'); 
        }else{
          this.errEmailPasswordList.errShow = false;
        }
      }
    },
    phonePassBlur(){
      if(!this.phonePassword){
        this.errPhonePasswordList.errShow = true;
        this.errPhonePasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt2'); 
      }else{
        if(!REG_PASSWORD.test(this.phonePassword)){
          this.errPhonePasswordList.errShow = true;
          this.errPhonePasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt3'); 
        }else{
          this.errPhonePasswordList.errShow = false;
        }
      }
    },
     // 获取邮箱验证码
    getEmailCode(){
      if(!this.email){
        this.errEmailList.errShow = true;
        this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt8'); 
        return; 
      }
      if(!REG_EMAIL.test(this.email)){
        this.errEmailList.errShow = true;
        this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt9'); 
        return; 
      }else{
        this.errEmailList.errShow = false;
      }
      let data = {
      // countryId: this.countryCurrent[0].countryId,
      mail: this.email,
      domainUrl:this.domainUrl,
      }
      account.emailCode(data).then((res)=>{
        if(res.data.success == 1){
          let _this = this;
          _this.verEmailCodeData.isVerCode = 1;
          _this.verEmailCodeData.interval = setInterval(function(){
            _this.verEmailCodeData.showTime--;
            if(_this.verEmailCodeData.showTime < 1){
              clearInterval(_this.verEmailCodeData.interval);
              _this.verEmailCodeData.interval ="";
              _this.verEmailCodeData.showTime = 60;
              _this.verEmailCodeData.isVerCode = 0;
            }
          },1000);
        }else{
          this.errEmailList.errShow = true;
          this.errEmailList.errContent = res.data.message;
        }
        
      }).catch(err=>{
        console.log(err.message);
      })
      this.errEmailList.errShow = false;
         
    },

    // 获取手机验证码
    getPhoneCode(){
      const regPhone = eval(this.countryCurrent[0].frontendRegex);
      console.log("frontendRegex"+regPhone);
      if(!this.phone){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt4');  
        return;
      }
      if(!regPhone.test(this.phone)){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt5');  
        return;
      }else{
        this.errShowList.errShow = false;
      }
      let data = {
        countryCode: this.countryCurrent[0].countryCallingCode,
        countryId: this.countryCurrent[0].countryId,
        phone: this.phone,
        type: 'mobile',
        domainUrl:this.domainUrl,
      }
      account.phoneCode(data).then((res)=>{
        if(res.data.success == 1){
          let _this = this;
          _this.verPhoneCodeData.isVerCode = 1;
          _this.verPhoneCodeData.interval = setInterval(function(){
            _this.verPhoneCodeData.showTime--;
            if(_this.verPhoneCodeData.showTime < 1){
              clearInterval(_this.verPhoneCodeData.interval);
              _this.verPhoneCodeData.interval ="";
              _this.verPhoneCodeData.showTime = 60;
              _this.verPhoneCodeData.isVerCode = 0;
            }
          },1000);
        }else{
          this.errShowList.errShow = true;
          this.errShowList.errContent = res.data.message; 
        }
        
      }).catch(err=>{
        console.log(err.message);
      })
      this.errShowList.errShow = false;
         
    },

    // 邮箱重置密码
    emailResetPassword(){
      if(!this.email){
        this.errEmailList.errShow = true;
        this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt8'); 
        return; 
      }
      if(!REG_EMAIL.test(this.email)){
        this.errEmailList.errShow = true;
        this.errEmailList.errContent = this.$i18n.t('loginRegister.checkPrompt9'); 
        return; 
      }else{
        this.errEmailList.errShow = false;
      }
      if(!this.emailCode){
        this.errEmailList.errShow = false;
        this.errEmailCodeList.errShow = true;
        this.errEmailCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
        return;
      }else{
        this.errEmailCodeList.errShow = false;
      }
      if(!this.emailPassword){
        this.errEmailCodeList.errShow = false;
        this.errEmailPasswordList.errShow = true;
        this.errEmailPasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt2'); 
        return; 
      }
      if(!REG_PASSWORD.test(this.emailPassword)){
        this.errEmailCodeList.errShow = false;
        this.errEmailPasswordList.errShow = true;
        this.errEmailPasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt3'); 
        return; 
      }else{
        this.errEmailPasswordList.errShow = false;
      }

      let data = {
        countryCode: this.countryCurrent[0].countryCallingCode,
        countryId: this.countryCurrent[0].countryId,
        value: this.email,
        code: this.emailCode,
        newPwd:this.emailPassword,
      }
      account.resetPassword(data).then((res)=>{
        if(res.data.success == 1){          
            const popTitle = this.$i18n.t('loginRegister.resetPasswordTitle');
            const cancel = this.$i18n.t('perfectData.cancel');
            const goLogin = this.$i18n.t('perfectData.goLogin');
            const title = this.$i18n.t('accountCenter.reminder');
            this.isShowConfirm = true
            this.$refs.myConfirm.show(popTitle, {
                type: 'alert',
                cancelText:cancel,
                confirmText: goLogin,
                titleText: title,
                data: '我是外界传进来的数据'
            })     
        }else{
          this.errEmailPasswordList.errShow = true;
          this.errEmailPasswordList.errContent = res.data.message;
          console.log(res.data.message);
        }
      }).catch(err=>{
        this.errEmailPasswordList.errShow = true;
        this.errEmailPasswordList.errContent = err.message;
      })       
       
    },

    // 手机号重置密码
    phoneResetPassword(){
      const regPhone = eval(this.countryCurrent[0].frontendRegex);
      console.log("frontendRegex"+regPhone);
      if(!this.phone){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt4'); 
        return;
      }
      if(!regPhone.test(this.phone)){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt5'); 
        return;
      }else{
        this.errShowList.errShow = false;
      }
      if(!this.phoneCode){
        this.errShowList.errShow = false;
        this.errPhoneCodeList.errShow = true;
        this.errPhoneCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
        return;
      }else{
        this.errPhoneCodeList.errShow = false;
      }
      if(!this.phonePassword){
        this.errPhoneCodeList.errShow = false;
        this.errPhonePasswordList.errShow = true;
        this.errPhonePasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt2'); 
        return; 
      }
      if(!REG_PASSWORD.test(this.phonePassword)){
        this.errPhoneCodeList.errShow = false;
        this.errPhonePasswordList.errShow = true;
        this.errPhonePasswordList.errContent = this.$i18n.t('loginRegister.checkPrompt3'); 
        return; 
      }else{
        this.errPhonePasswordList.errShow = false;
      }

      let data = {
        countryCode: this.countryCurrent[0].countryCallingCode,
        countryId: this.countryCurrent[0].countryId,
        value: this.phone,
        code: this.phoneCode,
        newPwd:this.phonePassword,
      }
      account.resetPassword(data).then((res)=>{
        if(res.data.success == 1){          
            const popTitle = this.$i18n.t('loginRegister.resetPasswordTitle');
            const cancel = this.$i18n.t('perfectData.cancel');
            const goLogin = this.$i18n.t('perfectData.goLogin');
            const title = this.$i18n.t('accountCenter.reminder');
            this.isShowConfirm = true
            this.$refs.myConfirm.show(popTitle, {
                type: 'alert',
                cancelText:cancel,
                confirmText: goLogin,
                titleText: title,
                data: '我是外界传进来的数据'
            })    
        }else{
          this.errPhonePasswordList.errShow = true;
          this.errPhonePasswordList.errContent = res.data.message;
          console.log(res.data.message);
        }
      }).catch(err=>{
        this.errPhonePasswordList.errShow = true;
        this.errPhonePasswordList.errContent = err.message;
      })       
       
    },
    

    // 提示框组件传递取消和确定事件,根据对应的事件类型跳转或关闭提示框
    userBehaviorFun(type){
      const that = this;     
        if(type === "clickCancel"){
          this.isShowConfirm = false   
          that.$router.push("./login"); 
        }else if(type === "clickConfirm"){
          that.$router.push("./login");
          this.isShowConfirm = false    
        }              
    },
  }
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
